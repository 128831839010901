<template>
  <div class="calf">
    <!-- banner部分 -->
    <div class="calf-banner">
      <div class="calf-bannerbox">
        <div class="calf-leftbox">
          <div class="calf-contentbox">
            <div class="calf-title family-title">犊牛智能精准饲喂一体机</div>
            <div class="calf-miaosu family-text">
              对比传统思维模式，犊牛系统饲喂系统饲喂量更加精准、营养配比更加科学、饲喂效率更高、实现犊牛精细化管理
            </div>
            <div class="calf-shen">
              <div class="calf-ansen family-picturetext" @click="Calfuse">
                申请体验
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 设备功能特点 -->
    <div class="device-box">
      <div class="device-centerbox">
        <div class="device-top">
          <div class="device-title family-title">设备功能特点</div>
        </div>
        <div class="device-bottom">
          <img src="https://qncdn.uni-sheep.com/s/Frame%20980.png" alt="" />
        </div>
      </div>
    </div>
    <!-- 硬件综合规划 -->
    <div class="software-modelbox">
      <div class="software-center" v-if="softwaredisplay">
        <div class="software-top">
          <div class="software-title family-title">软硬件综合规划</div>
          <div class="software-secon">硬件部署逻辑及软件数据分析</div>
        </div>
        <div class="software-bottom">
          <img src="https://qncdn.uni-sheep.com/s/Frame%20981.png" alt="" />
        </div>
      </div>
    </div>
    <!-- 为什么犊牛饲喂一体机要直接回传和牧兴邦(UniDairy)管理平台？ -->
    <div class="why-box">
      <div class="why-cenbox" v-if="whydisplay">
        <div class="why-topbox">
          <div class="why-title family-title">
            为什么犊牛饲喂一体机要直接回传和牧兴邦(UniDairy)管理平台？
          </div>
        </div>
        <div class="why-bottombox">
          <img src="https://qncdn.uni-sheep.com/s/Group%20922%281%29.png" alt="" />
        </div>
      </div>
    </div>
    <!-- 3种模式适用场景 -->
    <div class="three-box">
      <div class="three-centerbox" v-if="threedisplay">
        <div class="three-topbox">
          <div class="three-title family-title">3种模式适用场景</div>
          <div class="three-secon">
            定制化饲喂流程结束，该牛只自动返回正常饲喂流程
          </div>
        </div>
        <div class="three-bottombox">
          <img src="https://qncdn.uni-sheep.com/s/Group%20912%281%29.png" alt="" />
        </div>
      </div>
    </div>
    <!-- 饲喂数据回传 -->
    <div class="backhaul">
      <div class="back-center" v-if="backdisplay">
        <div class="back-lef">
          <div class="back-tit family-title">饲喂数据回传</div>
          <div class="back-miao family-text">
            饲喂数据每班次饲喂结束后自动回传和牧兴邦（UniDairy）管理平台，以管理平台独有的“分析报表”展示饲喂数据，更加直观、快速找出饲喂问题
          </div>
        </div>
        <div class="back-rig">
          <img
            src="https://qncdn.uni-sheep.com/s/Group%20987.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <!-- 公共脚步部分 -->
    <public-footer @Immediately="Immediately"></public-footer>
  </div>
</template>
<script>

import PublicFooter from "../../../components/publicfooter/index.vue";

export default {
  metaInfo: {
    title: '犊牛智能精准饲喂系统-自牧天成科技发展有限公司',
    // meta: [
    //   {
    //     name: 'description',
    //     content: 'This is my page description'
    //   }
    // ]
  },
  components: {
    PublicFooter,
  },
  props: [],
  data() {
    return {
      // 硬件综合规划动画
      softwaredisplay: false,
      // 为什么犊牛饲喂一体机要直接回传和牧兴邦(UniDairy)管理平台？动画
      whydisplay: false,
      // 3种模式适用场景动画
      threedisplay: false,
      //饲喂数据回传动画
      backdisplay: false,
    };
  },
  methods: {
    // 立即咨询
    Immediately() {
      this.$router.push("/fillinformation");
    },
    // 申请体验
    Calfuse(){
      this.$router.push("/fillinformation");
    },
    // 监听浏览器
    listenerFunction() {
      document.addEventListener("scroll", this.handleScroll, true);
    },
    // 获取浏览器滚动条高度
    handleScroll(event) {
      // console.log("滚动条高度为：", event.target.scrollTop);
      this.scrollTop = event.target.scrollTop;
      const topObj = {
        450: "softwaredisplay",
        1300: "whydisplay",
        1800: "threedisplay",
        2700: "backdisplay",
      };
      for (const top in topObj) {
        this.scrollTop >= top && (this[topObj[top]] = true);
      }
    },
  },
  created() {
    this.listenerFunction();
  },
  beforeDistroy() {
    document.removeEventListener("scroll", this.listenerFunction);
  },
  distroyed() {},
};
</script>
<style scoped lang="scss">

// 公共动画
@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.calf {
  width: 100%;
  height: 100%;
  //banner部分
  .calf-banner {
    width: 100%;
    height: 54.5625rem;
    background: url("https://qncdn.uni-sheep.com/s/banner%E8%83%8C%E6%99%AF%E5%9B%BE%281%29.png") center
      center;
    background-repeat: no-repeat;
    background-size: cover;
    .calf-bannerbox {
      width: 72.5rem;
      height: 100%;
      margin: 0 auto;
      display: flex;
      .calf-leftbox {
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        .calf-contentbox {
          width: 30rem;
          height: 18rem;
          display: flex;
          flex-direction: column;
          .calf-title {
            font-size: 2rem;
            font-weight: 800;
            padding-left: 0.5rem;
            color:#FFFFFF;
          }
          .calf-miaosu {
            width: 100%;
            height: 4rem;
            line-height: 2rem;
            font-size: 1rem;
            color:#FFFFFF;
            margin-top: 1rem;
          }
          .calf-shen {
            width: 100%;
            height: 3rem;
            color:#FFFFFF;
            .calf-ansen{
              width: 142px;
              height: 49px;
              background: #3371FF;
              text-align: center;
              line-height:49px ;
              color:white;
              border-radius: 5px;
              cursor: pointer;
              margin-top: 1.5rem;
            }
            .calfnsen:hover{
              background: blue;
             
            }
          }
        }
      }
    }
  }
  // 设备功能特点
  .device-box {
    width: 100%;
    height: 50rem;
    .device-centerbox {
      width: 72.5rem;
      height: 100%;
      margin: 0 auto;
      .device-top {
        width: 13rem;
        height: 4rem;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-top: 2.5rem;
        .device-title {
          font-size: 2rem;
          font-weight: 600;
          text-align: center;
        }
        .device-secon {
          font-size: 16px;
          color: #353a67;
          text-align: center;
        }
      }
      .device-bottom {
        width: 100%;
        height: 38.25rem;
        margin-top: 3rem;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  // 硬件综合规划
  .software-modelbox {
    width: 100%;
    height: 46rem;
    .software-center {
      width: 72.5rem;
      height: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      animation: slide-up 1.5s ease-in-out;
      animation-fill-mode: forwards;
      .software-top {
        width: 19rem;
        height: 4.5rem;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-top: 2.5rem;
        .software-title {
          font-size: 2rem;
          font-weight: 600;
          text-align: center;
        }
        .software-secon {
          font-size: 1.3rem;
          color: #353a67;
          text-align: center;
        }
      }
      .software-bottom {
        width: 100%;
        height: 32rem;
        margin-top: 3rem;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  // 为什么犊牛饲喂一体机要直接回传和牧兴邦(UniDairy)管理平台？
  .why-box {
    width: 100%;
    height: 29rem;
    .why-cenbox {
      width: 72.5rem;
      height: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      animation: slide-up 1.5s ease-in-out;
      animation-fill-mode: forwards;
      .why-topbox {
        width: 64rem;
        height: 4rem;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-top: 1.5rem;
        .why-title {
          font-size: 2rem;
          font-weight: 600;
          text-align: center;
        }
        .why-secon {
          font-size: 1rem;
          color: #353a67;
          text-align: center;
        }
      }
      .why-bottombox {
        width: 100%;
        height: 20.125rem;
       
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  //3种模式适用场景
  .three-box {
    width: 100%;
    height: 59.5rem;
    .three-centerbox {
      width: 72.5rem;
      height: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      animation: slide-up 1.5s ease-in-out;
      animation-fill-mode: forwards;
      .three-topbox {
        width: 40rem;
        height: 4.5rem;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-top: 5rem;
        .three-title {
          font-size: 2rem;
          font-weight: 600;
          text-align: center;
        }
        .three-secon {
          font-size: 1.3rem;
          color: #353a67;
          text-align: center;
        }
      }
      .three-bottombox {
        width: 100%;
        height: 42.25rem;
        margin-top: 3rem;
      }
    }
  }
  //饲喂数据回传
  .backhaul {
    width: 100%;
    height: 30rem;
    .back-center {
      width: 72.5rem;
      height: 82%;
      margin: 0 auto;
      display: flex;
      margin-top: 2rem;
      animation: slide-up 1.5s ease-in-out;
      animation-fill-mode: forwards;
      .back-lef {
        width: 40%;
        height: 100%;
        .back-tit {
          font-size: 2rem;
          font-weight: 800;
          margin-top: 2rem;
        }
        .back-miao {
          width: 25.9375rem;
          height: 10rem;
          color: #666666;
          font-size: 1.2rem;
          line-height: 2.4rem;
          margin-top: 2rem;
          text-align:justify;
        }
      }
      .back-rig {
        width: 60%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
