var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"calf"},[_c('div',{staticClass:"calf-banner"},[_c('div',{staticClass:"calf-bannerbox"},[_c('div',{staticClass:"calf-leftbox"},[_c('div',{staticClass:"calf-contentbox"},[_c('div',{staticClass:"calf-title family-title"},[_vm._v("犊牛智能精准饲喂一体机")]),_c('div',{staticClass:"calf-miaosu family-text"},[_vm._v(" 对比传统思维模式，犊牛系统饲喂系统饲喂量更加精准、营养配比更加科学、饲喂效率更高、实现犊牛精细化管理 ")]),_c('div',{staticClass:"calf-shen"},[_c('div',{staticClass:"calf-ansen family-picturetext",on:{"click":_vm.Calfuse}},[_vm._v(" 申请体验 ")])])])])])]),_vm._m(0),_c('div',{staticClass:"software-modelbox"},[(_vm.softwaredisplay)?_c('div',{staticClass:"software-center"},[_vm._m(1),_vm._m(2)]):_vm._e()]),_c('div',{staticClass:"why-box"},[(_vm.whydisplay)?_c('div',{staticClass:"why-cenbox"},[_vm._m(3),_vm._m(4)]):_vm._e()]),_c('div',{staticClass:"three-box"},[(_vm.threedisplay)?_c('div',{staticClass:"three-centerbox"},[_vm._m(5),_vm._m(6)]):_vm._e()]),_c('div',{staticClass:"backhaul"},[(_vm.backdisplay)?_c('div',{staticClass:"back-center"},[_vm._m(7),_vm._m(8)]):_vm._e()]),_c('public-footer',{on:{"Immediately":_vm.Immediately}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"device-box"},[_c('div',{staticClass:"device-centerbox"},[_c('div',{staticClass:"device-top"},[_c('div',{staticClass:"device-title family-title"},[_vm._v("设备功能特点")])]),_c('div',{staticClass:"device-bottom"},[_c('img',{attrs:{"src":"https://qncdn.uni-sheep.com/s/Frame%20980.png","alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"software-top"},[_c('div',{staticClass:"software-title family-title"},[_vm._v("软硬件综合规划")]),_c('div',{staticClass:"software-secon"},[_vm._v("硬件部署逻辑及软件数据分析")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"software-bottom"},[_c('img',{attrs:{"src":"https://qncdn.uni-sheep.com/s/Frame%20981.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"why-topbox"},[_c('div',{staticClass:"why-title family-title"},[_vm._v(" 为什么犊牛饲喂一体机要直接回传和牧兴邦(UniDairy)管理平台？ ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"why-bottombox"},[_c('img',{attrs:{"src":"https://qncdn.uni-sheep.com/s/Group%20922%281%29.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"three-topbox"},[_c('div',{staticClass:"three-title family-title"},[_vm._v("3种模式适用场景")]),_c('div',{staticClass:"three-secon"},[_vm._v(" 定制化饲喂流程结束，该牛只自动返回正常饲喂流程 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"three-bottombox"},[_c('img',{attrs:{"src":"https://qncdn.uni-sheep.com/s/Group%20912%281%29.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"back-lef"},[_c('div',{staticClass:"back-tit family-title"},[_vm._v("饲喂数据回传")]),_c('div',{staticClass:"back-miao family-text"},[_vm._v(" 饲喂数据每班次饲喂结束后自动回传和牧兴邦（UniDairy）管理平台，以管理平台独有的“分析报表”展示饲喂数据，更加直观、快速找出饲喂问题 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"back-rig"},[_c('img',{attrs:{"src":"https://qncdn.uni-sheep.com/s/Group%20987.png","alt":""}})])
}]

export { render, staticRenderFns }